<template>
  <registrasi-step-1 />
</template>

<script>
  import RegistrasiStep1 from '../RegistrasiStep1.vue'

  export default {
    components: {
      'registrasi-step-1': RegistrasiStep1,
    },

    data: () => ({}),
  }
</script>
