<template>
  <v-app id="register-universitas">
    <header-register :step="2" />
    <v-container fluid>
      <div class="main-container">
        <div class="pb-6" style="width: 80vh">
          <v-row>
            <v-col class="txt-sub-6" cols="12"> Data Diri </v-col>
          </v-row>

          <v-row class="mt-n2">
            <v-col class="txt-sub-1">
              Silahkan lengkapi beberapa data diri di bawah
            </v-col>
          </v-row>

          <template v-if="regType == 2">
            <v-row class="mt-8">
              <v-col class="txt-sub-1" cols="12" sm="4">
                Universitas <span class="symbol-required">*</span>
              </v-col>

              <v-col cols="12" sm="8">
                <v-custom-select
                  v-model="selectedUniversitas"
                  :options="universitas"
                  :clearable="false"
                  label="name_university"
                  @search="getUniversitas"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    <span class="mr-10"> Ketik minimal 3 huruf </span>
                  </template>
                </v-custom-select>
              </v-col>
            </v-row>
          </template>

          <v-row class="">
            <v-col class="txt-sub-1" cols="12" sm="4">
              Nomor Telepon <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="nomorTelepon"
                name="nomor-telepon"
                type="text"
                outlined
                color="#36AC87"
                autocomplete="off"
                dense
                placeholder="Nomor HP yang memiliki WhatsApp"
              />
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col class="txt-sub-1" cols="12" sm="4">
              E-mail <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="email"
                name="email"
                type="text"
                outlined
                color="#36AC87"
                autocomplete="off"
                dense
                placeholder="Email"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col class="txt-sub-1" cols="12" sm="4">
              Nomor KTP <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="nomorKTP"
                name="nomor-ktp"
                type="text"
                counter
                maxlength="16"
                outlined
                autocomplete="off"
                color="#36AC87"
                dense
                placeholder="No. KTP"
              />
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col class="txt-sub-1" cols="12" sm="4">
              Nama Lengkap <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="namaLengkap"
                name="nama-lengkap"
                type="text"
                outlined
                autocomplete="off"
                dense
                color="#36AC87"
                placeholder="Masukkan nama lengkap"
              />
            </v-col>
          </v-row>

          <v-row class="mt-n8">
            <v-col
              class="txt-sub-1 d-flex"
              cols="12"
              sm="4"
              style="align-items: center"
            >
              Jenis Kelamin <span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
              class="d-flex"
              style="flex-direction: column"
            >
              <v-radio-group v-model="jenisKelamin" row>
                <v-radio value="L" color="#38BA98">
                  <template v-slot:label>
                    <span class="txt-sub-1"> Pria </span>
                  </template>
                </v-radio>

                <v-radio value="P" color="#38BA98">
                  <template v-slot:label>
                    <span class="txt-sub-1"> Wanita </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col class="txt-sub-1" cols="12" sm="4">
              Tempat Tanggal Lahir<span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
              class="d-flex"
              style="flex-direction: column"
            >
              <div>
                <v-custom-select
                  v-model="selectedTempatLahir"
                  :options="tempatLahir"
                  :clearable="false"
                  label="city_name"
                  @search="getTempatLahir"
                  autocomple="off"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    <span class="mr-10"> Ketik minimal 3 huruf </span>
                  </template>
                </v-custom-select>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col class="txt-sub-1" cols="12" sm="4"> </v-col>

            <v-col
              cols="12"
              sm="8"
              class="d-flex"
              style="flex-direction: column"
            >
              <div data-app>
                <v-dialog
                  ref="dialog"
                  v-model="modalTanggalLahir"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      color="#36AC87"
                      v-bind="attrs"
                      dense
                      outlined
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#36AC87"
                    :max="minAge()"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="modalTanggalLahir = false"
                    >
                      Batal
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      Pilih
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt-sub-1" cols="12" sm="4">
              Agama <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-custom-select
                v-model="selectedAgama"
                :options="agama"
                :clearable="false"
                label="name_belief"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt-sub-1" cols="12" sm="4">
              Pendidikan Terakhir <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-custom-select
                v-model="selectedPendidikanTerakhir"
                :options="pendidikanTerakhir"
                :clearable="false"
                label="name_education"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt-sub-1" cols="12" sm="4">
              Alamat <span class="symbol-required">*</span>
            </v-col>

            <v-col
              class="d-flex"
              cols="12"
              sm="8"
              style="flex-direction: column"
            >
              <div class="mt-2">
                <v-textarea
                  v-model="alamatLengkap"
                  rows="4"
                  no-resize
                  outlined
                  color="#36AC87"
                  placeholder="Masukkan Alamat Lengkap"
                />
              </div>

              <div class="mt-2">
                <v-custom-select
                  v-model="selectedKabupatenKota"
                  :options="kabupatenKota"
                  :clearable="false"
                  label="name_address"
                  @search="getAddress"
                  @option:selected="getKodePos"
                  autocomplete="off"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    <span class="mr-10"> Ketik minimal 3 huruf </span>
                  </template>
                </v-custom-select>
              </div>

              <div class="mt-2">
                <v-custom-select
                  v-model="selectedKodePos"
                  :options="kodePos"
                  :clearable="false"
                  autocomplete="off"
                />
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col class="txt-sub-1" cols="12" sm="4">
              Organisasi lain yang diikuti
            </v-col>

            <v-col
              class="d-flex"
              cols="12"
              sm="8"
              style="flex-direction: column"
            >
              <div v-for="i in organisasi" :key="'organisasi-' + i">
                <v-text-field
                  :id="'organisasi-' + i"
                  type="text"
                  outlined
                  autocomplete="off"
                  dense
                  color="#36AC87"
                  placeholder="Masukkan Organisasi"
                />
              </div>

              <!-- <div>
                <v-btn
                  class="text-capitalize"
                  outlined
                  block
                  color="#2E976C"
                  @click="organisasi++"
                >
                  Tambah Kolom
                </v-btn>
              </div> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt-sub-1 d-flex pt-6"
              cols="12"
              sm="4"
              style="flex-direction: column"
            >
              <div>Foto</div>
            </v-col>

            <v-col cols="12" sm="8">
              <upload-button
                :is-loading="isLoading[1]"
                :upload-file="uploadedFile[1]"
                @uploadButtonClick="uploadFile(1)"
              />
              <v-file-input
                id="upload-file"
                style="display: none"
                @change="upload"
              />
              <small class="ml-3">Dimensi 3x4; Ukuran maksimal 2Mb</small>
            </v-col>
          </v-row>

          <!-- Alert -->
          <v-row class="mb-n8">
            <v-col>
              <v-alert v-model="showAlert" type="error" dismissible>
                {{ alertText }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row class="my-8">
            <v-col>
              <v-btn
                class="text-capitalize"
                color="#36AC87"
                block
                x-large
                :disabled="disableButton"
                small
                @click="submit"
              >
                <span class="txt-login"> Lanjutkan Pendaftaran </span>
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col class="text-center">
              <span
                class="txt-14-gray-80"
                style="cursor: pointer"
                @click="navBack"
              >
                <v-icon class="pb-1">
                  mdi-chevron-left
                </v-icon>
                Kembali ke halaman sebelumnya
              </span>
            </v-col>
          </v-row> -->
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
// import { mapState } from 'vuex'
import UploadButton from "../komponen/UploadButton.vue";
import RegistrasiHeader from "../registrasi/RegistrasiHeader.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    "upload-button": UploadButton,
    "header-register": RegistrasiHeader,
  },

  data: () => ({
    // Form Data:
    regType: localStorage.getItem("reg-type"),
    selectedUniversitas: [
      {
        name_university: "Pilih Universitas",
      },
    ],
    universitas: [],

    nomorKTP: null,
    namaLengkap: null,
    jenisKelamin: null,

    selectedTempatLahir: [
      {
        city_name: "Ketik Kota / Kab Tempat Lahir",
      },
    ],
    tempatLahir: [],

    tanggalLahir: null,
    date: new Date(new Date().getFullYear() - 18).toISOString().substr(0, 10),
    minAgeNum: 18,

    modalTanggalLahir: false,

    selectedAgama: [
      {
        name_belief: "Pilih Agama",
      },
    ],
    agama: [],

    selectedPendidikanTerakhir: [
      {
        name_education: "Pilih Pendidikan Terakhir",
      },
    ],
    pendidikanTerakhir: [],

    selectedKabupatenKota: [
      {
        name_address: "Kabupaten / Kota / Kecamatan",
      },
    ],
    kabupatenKota: [],

    selectedKodePos: [
      {
        label: "Pilih Kode Pos",
      },
    ],
    kodePos: [],

    alamatLengkap: null,
    nomorTelepon: null,
    email: null,
    organisasi: 3,

    // Form Data Darurat Section:
    // namaLengkapDarurat: "",

    // nomorTeleponDarurat: "",

    // relasiDarurat: "anak",

    // selectedKabupatenKotaDarurat: [
    //   {
    //     name_address: "Kabupaten / Kota / Kecamatan",
    //   },
    // ],
    // kabupatenKotaDarurat: [],

    // selectedKodePosDarurat: [
    //   {
    //     label: "Pilih Kode Pos",
    //   },
    // ],
    // kodePosDarurat: [],

    // alamatLengkapDarurat: "",

    photoHash: "",

    disableButton: false,

    // Alert:
    showAlert: false,
    alertText: "Error",

    uploadKey: 0,

    isLoading: {
      1: false,
    },

    uploadedFile: {
      1: {},
    },
  }),

  computed: {},

  watch: {},

  created() {
    this.getAgama();
    this.getPendidikan();
    this.getUniversitas("uni");
  },

  methods: {
    minAge() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - this.minAgeNum);
      return date.toISOString().substr(0, 10);
    },

    getUniversitas(e) {
      if (e.length < 3) return;

      const requestBody = {
        search: e,
      };

      axios
        .post("/v1/general/get-university", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.universitas = res.data.data.university.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getTempatLahir(e) {
      if (e.length < 3) return;

      const requestBody = {
        search: e,
      };

      axios
        .post("/v1/general/get-city", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.tempatLahir = res.data.data.city.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getAgama() {
      const requestBody = {};

      axios
        .post("/v1/general/get-belief", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.agama = res.data.data.belief.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getPendidikan() {
      const requestBody = {};

      axios
        .post("/v1/general/get-education", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.pendidikanTerakhir = res.data.data.education.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getAddress(e) {
      if (e.length < 2) return;

      const requestBody = {
        search: e,
      };

      axios
        .post("/v1/general/get-address", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.kabupatenKota = res.data.data.address.list;
            // this.kabupatenKotaDarurat = res.data.data.address.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getKodePos(e) {
      // console.log(e)
      const requestBody = {
        hash_address: e.hash_address,
      };

      axios
        .post("/v1/general/get-postal-code", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.kodePos = res.data.data.postal_code.list;
            // this.kodePosDarurat = res.data.data.postal_code.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    submit() {
      this.disableButton = true;

      // data organisasi:
      const org = [];
      for (let index = 1; index < this.organisasi + 1; index++) {
        const dt = document.getElementById("organisasi-" + index).value;
        org.push(dt);
      }

      const requestBody = {
        reg_hash: localStorage.getItem("step-1"),
        reg_university: this.selectedUniversitas.hash_university,
        reg_nik: this.nomorKTP,
        reg_name: this.namaLengkap,
        reg_gender: this.jenisKelamin,
        reg_birth_city: this.selectedTempatLahir.city_id,
        reg_birth_date: this.date,
        reg_belief_id: this.selectedAgama.hash_belief,
        reg_education_id: this.selectedPendidikanTerakhir.hash_education,
        reg_address_hash: this.selectedKabupatenKota.hash_address,
        reg_address_pos: this.selectedKodePos,
        reg_address: this.alamatLengkap,
        reg_phone: this.nomorTelepon,
        reg_email: this.email,
        reg_organization: org,
        reg_photo: this.photoHash,

        // section darurat:
        // reg_emgc_name: this.namaLengkapDarurat,
        // reg_emgc_phone: this.nomorTeleponDarurat,
        // reg_emgc_connection: this.relasiDarurat,
        // reg_emgc_address_hash: this.selectedKabupatenKotaDarurat.hash_address,
        // reg_emgc_address_pos: this.selectedKodePosDarurat,
        // reg_emgc_address: this.alamatLengkapDarurat,
      };

      axios
        .post("/v1/auth/register/step-2", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            const regType = localStorage.getItem("reg-type");
            localStorage.setItem("tmp-2", JSON.stringify(requestBody));

            switch (regType) {
              case "1":
                this.$router.push({ name: "RegisterProfesionalPerusahaan" });
                break;

              case "2":
                this.$router.push({ name: "Password" });
                break;
            }
          }
        })
        .catch((e) => {
          this.disableButton = false;

          if (typeof e.response.data.error === "object") {
            this.setAlert(Object.values(e.response.data.error)[0][0]);
          } else {
            this.setAlert(e.response.data.error);
          }
        });
    },

    uploadFile(key) {
      this.uploadKey = key;
      document.getElementById("upload-file").click();
    },

    upload(e) {
      this.isLoading[this.uploadKey] = true;
      const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const requestBody = new FormData();
      requestBody.append("file", e);
      requestBody.append("dest", "member_photo");

      axios
        .post(baseCDNurl + "/v1/upload", requestBody, config)
        .then((res) => {
          if (res.data.status === 200) {
            this.isLoading[this.uploadKey] = false;
            this.uploadedFile[this.uploadKey] = res.data.data;
            this.photoHash = res.data.data.tmp_file_hash;
          }
        })
        .catch((e) => {
          this.isLoading[this.uploadKey] = false;
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    navBack() {
      this.$router.push({ name: "Register" });
    },

    setAlert(txt) {
      this.showAlert = true;
      this.alertText = txt;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-login {
  @extend .p-1;
  color: #ffffff;
  font-weight: bold;
}

.txt-sub-1 {
  @extend .p-1;
  color: $gray-50;
}

.txt-sub-2 {
  @extend .p-1;
  color: $gray-50;
  font-style: italic;
}

.txt-sub-3 {
  @extend .p-2;
  color: $gray-50;
}

.txt-sub-4 {
  @extend .p-2;
  color: $blue-1;
  cursor: pointer;
  font-weight: bold;
}

.txt-sub-5 {
  @extend .p-2;
  color: $gray-80;
}

.txt-sub-6 {
  @extend .h-5;
  color: $black-1;
  font-weight: bold;
}

.txt-btn-login {
  @extend .h-5;
  color: #ffffff;
  font-weight: bold;
}

.btn-login {
  color: $green-2;
}

.symbol-required {
  color: $red-1;
}

.txt-14-gray-80 {
  @include font-common;
  color: $gray-80;
}
</style>
