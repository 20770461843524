<template>
  <div class="header-container">
    <v-row class="mx-0 px-0 py-8">
      <v-col class="txt-sub-1 text-center">
        <span class="px-2">
          <div
            class="circle"
            :style="
              'background-color:' +
              getAvatarColor(1) +
              ';' +
              'border: 1px solid ' +
              getNumberColor(1) +
              ';'
            "
          >
            <span :style="'color:' + getNumberColor(1)">1</span>
          </div>
          <span :style="getTextColor(1)"> Asal Keanggotaan </span>
        </span>

        <span class="px-2">
          <div
            class="circle"
            :style="
              'background-color:' +
              getAvatarColor(2) +
              ';' +
              'border: 1px solid ' +
              getNumberColor(2) +
              ';'
            "
          >
            <span :style="'color:' + getNumberColor(2)">2</span>
          </div>
          <span :style="getTextColor(2)"> Data Diri / Entitas </span>
        </span>

        <span class="px-2">
          <div
            class="circle"
            :style="
              'background-color:' +
              getAvatarColor(3) +
              ';' +
              'border: 1px solid ' +
              getNumberColor(3) +
              ';'
            "
          >
            <span :style="'color:' + getNumberColor(3)">3</span>
          </div>
          <span :style="getTextColor(3)"> Konfirmasi </span>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UploadButton",

  props: {
    step: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({}),

  methods: {
    uploadBtnClick() {
      this.$emit("uploadButtonClick");
    },

    getAvatarColor(key) {
      let clr = "";

      switch (key) {
        case 1:
          if (this.step === 1) {
            clr = "#36AC87";
          } else {
            clr = "#FAFAFA";
          }
          break;
      }

      switch (key) {
        case 2:
          if (this.step === 2) {
            clr = "#36AC87";
          } else {
            clr = "#FAFAFA";
          }
          break;
      }

      switch (key) {
        case 3:
          if (this.step === 3) {
            clr = "#36AC87";
          } else {
            clr = "#FAFAFA";
          }
          break;
      }

      return clr;
    },

    getNumberColor(key) {
      let clr = "";

      switch (key) {
        case 1:
          if (this.step === 1) {
            clr = "#FAFAFA";
          } else {
            clr = "#36AC87";
          }
          break;
      }

      switch (key) {
        case 2:
          if (this.step === 2) {
            clr = "#FAFAFA";
          } else {
            clr = "#36AC87";
          }
          break;
      }

      switch (key) {
        case 3:
          if (this.step === 3) {
            clr = "#FAFAFA";
          } else {
            clr = "#36AC87";
          }
          break;
      }

      return clr;
    },

    getTextColor(key) {
      let clr = "";

      switch (key) {
        case 1:
          if (this.step === 1) {
            clr = "color: #36AC87";
          } else {
            clr = "color: #808080";
          }
          break;
      }

      switch (key) {
        case 2:
          if (this.step === 2) {
            clr = "color: #36AC87";
          } else {
            clr = "color: #808080";
          }
          break;
      }

      switch (key) {
        case 3:
          if (this.step === 3) {
            clr = "color: #36AC87";
          } else {
            clr = "color: #808080";
          }
          break;
      }

      return clr;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  background: $gray-98;
  position: relative;
  top: 0;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}

.txt16-gray80 {
  @extend .p-1;
  color: $gray-80;
}

.txt12-green2-bold {
  @extend .p-2;
  color: $green-2;
  font-weight: bold;
}

.circle {
  padding-top: 1px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
</style>
