var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-container"},[_c('v-row',{staticClass:"mx-0 px-0 py-8"},[_c('v-col',{staticClass:"txt-sub-1 text-center"},[_c('span',{staticClass:"px-2"},[_c('div',{staticClass:"circle",style:('background-color:' +
            _vm.getAvatarColor(1) +
            ';' +
            'border: 1px solid ' +
            _vm.getNumberColor(1) +
            ';')},[_c('span',{style:('color:' + _vm.getNumberColor(1))},[_vm._v("1")])]),_c('span',{style:(_vm.getTextColor(1))},[_vm._v(" Asal Keanggotaan ")])]),_c('span',{staticClass:"px-2"},[_c('div',{staticClass:"circle",style:('background-color:' +
            _vm.getAvatarColor(2) +
            ';' +
            'border: 1px solid ' +
            _vm.getNumberColor(2) +
            ';')},[_c('span',{style:('color:' + _vm.getNumberColor(2))},[_vm._v("2")])]),_c('span',{style:(_vm.getTextColor(2))},[_vm._v(" Data Diri / Entitas ")])]),_c('span',{staticClass:"px-2"},[_c('div',{staticClass:"circle",style:('background-color:' +
            _vm.getAvatarColor(3) +
            ';' +
            'border: 1px solid ' +
            _vm.getNumberColor(3) +
            ';')},[_c('span',{style:('color:' + _vm.getNumberColor(3))},[_vm._v("3")])]),_c('span',{style:(_vm.getTextColor(3))},[_vm._v(" Konfirmasi ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }